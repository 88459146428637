/* eslint-disable fp/no-mutation */
import type { CookiesUnmapped, DeviceoutputMapped } from '@/core/features/cookies/cookies-service';
import type { UserValidationProperties } from '@/core/features/user/user-validation-type';
import type { WireframeSalutation } from '@/core/features/wireframe/wireframe-types';
import type { Element } from 'domhandler';
import type { HTMLReactParserOptions } from 'html-react-parser';

import parse from 'html-react-parser';

import { WIREFRAME_CHECK24_DOMAIN_PLACEHOLDER } from '@packages/wireframe/src/constants';

import { IS_NODE_ENV_DEV, IS_SERVER, NEXT_PUBLIC_CHECK24_DOMAIN } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';
import getConsentBannerPrereneringClassFromCookies from '@/core/features/wireframe/wireframe-manipulation/consent-banner/consent-banner-prerendering';

export const elementIncludesId = (node: Element, id: string) => node.attribs.id?.includes(id);

export const elementIncludesClassName = (node: Element, className: string) => node.attribs.class?.includes(className);

export const parseWireframeHead = (html: string) => {
    const headStringEnd = html.indexOf('</head>');
    const htmlHeadString = html.substring(0, headStringEnd + 100);
    const extractedWireframeHeadElements = new RegExp(/<head>(.+)<\/head>/, 'sg').exec(htmlHeadString);
    if (!Array.isArray(extractedWireframeHeadElements) || !extractedWireframeHeadElements[1]) {
        throw 'Head Element in Wireframe was not found';
    }
    return parse(extractedWireframeHeadElements[1]);
};

export const parseWireframeContent = (html: string, parserOptions: HTMLReactParserOptions) => {
    const wireframeBodyHtmlString = getWireframeBodyHTMLString(html);

    const parsedPageContent = parse(wireframeBodyHtmlString, parserOptions);
    handleUnhandledPlaceholder(parsedPageContent);

    const wireframeContent = Array.isArray(parsedPageContent)
        ? parsedPageContent.filter((node) => node.type !== undefined)
        : [];

    if (wireframeContent.length === 0) {
        throw 'wireframeBodyHTMLString is invalid';
    }

    return wireframeContent;
};

export const getWireframeBodyAttributes = (
    html: string,
    deviceoutputMapped: DeviceoutputMapped,
    cookies: CookiesUnmapped,
    okResponse: boolean,
) => {
    const cookieConsentPrerenderingClassName = !okResponse
        ? ''
        : getConsentBannerPrereneringClassFromCookies(deviceoutputMapped, cookies);

    const bodyStringPosition = html.indexOf('<body');
    const bodyString = html.substring(bodyStringPosition - 10, bodyStringPosition + 100);

    const idMatch = bodyString.match(/<body.+?id="(.+?)"/);

    if (!Array.isArray(idMatch) || idMatch.length < 2) {
        if (deviceoutputMapped !== 'desktop') {
            throw 'Could not find id on wireframe body';
        }
    }
    const classNameMatch = bodyString.match(/<body.+?class="(.+?)"/);
    if (!Array.isArray(classNameMatch) || classNameMatch.length < 2) {
        throw 'Could not find class on wireframe body';
    }

    return {
        className: `body ${classNameMatch[1]} ${cookieConsentPrerenderingClassName}`,
        id: idMatch?.[1] ?? undefined,
    };
};

const getWireframeBodyHTMLString = (html: string) => {
    let wireframeBodyHtmlStringMatch = new RegExp(/<body (.+)<\/body>/, 'sg').exec(html);

    if (!Array.isArray(wireframeBodyHtmlStringMatch) || !wireframeBodyHtmlStringMatch[1]) {
        throw 'wireframe html seems to be invalid';
    }

    let wireframeBodyHtmlString = wireframeBodyHtmlStringMatch[1];

    wireframeBodyHtmlString = wireframeBodyHtmlString.replaceAll(
        WIREFRAME_CHECK24_DOMAIN_PLACEHOLDER,
        `${NEXT_PUBLIC_CHECK24_DOMAIN}`,
    );

    return wireframeBodyHtmlString;
};

const handleUnhandledPlaceholder = (content: JSX.Element | JSX.Element[] | string) => {
    if (IS_NODE_ENV_DEV && IS_SERVER) {
        const string = typeof content === 'string' ? content : JSON.stringify(content);
        const embedPos = string.indexOf('EMBED:');
        if (embedPos >= 0) {
            const debugString = string.substring(embedPos - 5, embedPos + 100);
            logger.error(`Found unhandled wireframe placeholders at ${embedPos}: ${debugString}...`, {
                serverOnly: true,
            });
        }
    }
};

export const getWireframeSalutationFromLoginStatus = (
    loginStatus: UserValidationProperties['loginStatus'],
): WireframeSalutation => {
    if (loginStatus === 'loggedout') {
        return 'guest';
    }

    if (loginStatus === 'active') {
        return 'user';
    }

    return loginStatus;
};
