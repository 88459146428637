import type { ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { updateVisibilityOnClientDesktop } from '@/core/features/wireframe/wireframe-manipulation/wireframe-element-visibility/wireframe-element-visibility-service-desktop';

export const useWireframeElementVisibilityHandlerDesktop = (layoutId: ILayoutId | null) => {
    React.useEffect(() => {
        updateVisibilityOnClientDesktop(layoutId);
    }, [layoutId]);
};
