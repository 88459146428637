import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { savePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';

export default function useWireframeScrollRestoration() {
    const [clientUrl] = useClientUrl();
    const originalLoginLayerOpen = React.useRef<(e?: Event) => void>();

    const scrollPositionStoreHandler = React.useCallback(() => {
        savePageScrollPosition(clientUrl);
    }, [clientUrl]);

    React.useEffect(() => {
        if (!window.Check24) {
            return;
        }

        originalLoginLayerOpen.current ??= window.Check24.uliloginlayer.open;

        // eslint-disable-next-line fp/no-mutation
        window.Check24.uliloginlayer.open = (e?: Event) => {
            scrollPositionStoreHandler();
            originalLoginLayerOpen.current?.(e);
        };
    }, [scrollPositionStoreHandler]);
}
