/* This file was automatically generated */
export const classC24TapBarVisible = 'c24-tap-bar-visible';
export const classC24mCookieConsentNotice = 'c24m-cookie-consent-notice';
export const classC24mCookieConsentButton = 'c24m-cookie-consent-button';
export const classC24mSab = 'c24m-sab';
export const classC24mSabClose = 'c24m-sab-close';
export const classC24mSearchSlim = 'c24m-search-slim';
export const classC24mHeaderBackWrapper = 'c24m-header-back-wrapper';
export const classC24mKbLogoutNavi = 'c24m-kb-logout-navi';
export const classC24mCustomer = 'c24m-customer';
export const classC24mContactWrapper = 'c24m-contact-wrapper';
export const classC24mContactLayerContent = 'c24m-contact-layer-content';
export const classC24mMylists = 'c24m-mylists';
export const classC24mSearchIconWrapper = 'c24m-search-icon-wrapper';
export const classC24mSearchIcon = 'c24m-search-icon';
export const classC24mInput = 'c24m-input';
export const classC24mPageContent = 'c24m-page-content';
export const classC24mFooter = 'c24m-footer';
export const classC24mTapBar = 'c24m-tap-bar';
export const classC24FooterLinkConditions = 'c24-footer-link-conditions';
export const classC24mCopyright = 'c24m-copyright';
export const idC24mPageBlockingLayer = 'c24m-page-blocking-layer';
export const idC24mSab = 'c24m-sab';
export const idC24mHeader = 'c24m-header';
export const idC24mNav = 'c24m-nav';
export const idC24mKbLoginNavi = 'c24m-kb-login-navi';
export const idC24mLogo = 'c24m-logo';