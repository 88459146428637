import type { UserValidationProperties } from '@/core/features/user/user-validation-type';

import React from 'react';

import { WIREFRAME_ULI_LOGIN_LAYER_EVENT, WIREFRAME_UNIFIED_LOGIN_EVENT } from '@packages/wireframe/src/constants';

import { useUser } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import BrowserStorage from '@/core/features/store/browser-storage';
import SessionStorage from '@/core/features/store/session-storage';
import initializeLoginLayer from '@/core/features/wireframe/services/wireframe-client-init-service';
import useWireframeLoaded from '@/core/features/wireframe/use-wireframe-loaded';
import { TIME_1S } from '@/core/utils/time';

const userSessionStorage = new SessionStorage<UserValidationProperties & { count: number }>('login', {
    defaultKey: '',
});

export default function useWireframeLogin() {
    const { deviceoutputMapped } = useDeviceoutput();
    const [user, setUser] = useUser();

    useWireframeLoaded(() => initializeLoginLayer(deviceoutputMapped));

    React.useEffect(() => {
        const updateAndSetUser = () => updateUser(user).then(setUser);

        // Update user on app login
        addEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, updateAndSetUser);

        // Update user on mobile/desktop login
        addEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, updateAndSetUser);

        // Update user on route transition (clientUrl changes)
        updateAndSetUser();

        return () => {
            removeEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, updateAndSetUser);
            removeEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, updateAndSetUser);
        };
    }, [setUser, user]);
}

const updateUser = (user: UserValidationProperties): Promise<UserValidationProperties> => {
    if (!BrowserStorage.isSupported(sessionStorage)) {
        return Promise.resolve(user);
    }

    const now = Date.now();
    const sessionUserData = userSessionStorage.get();

    const updatedTimestamp = Math.max(user.updatedTimestamp, sessionUserData?.updatedTimestamp ?? 0);

    if (now - updatedTimestamp < TIME_1S * 3) {
        // do not refetch data that is still fresh
        return Promise.resolve(user);
    }

    userSessionStorage.set({
        ...user,
        count: (sessionUserData?.count ?? 0) + 1,
        updatedTimestamp: now,
    });

    return fetch('/api/sso/validate-user') //
        .then((response) => response.json());
};
