/* eslint-disable fp/no-mutation */
import type { ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { Element } from 'domhandler';

import {
    classC24HeaderHover,
    classC24Notification,
    classC24SearchIcon,
    idC24SearchForm,
    idC24SearchHeader,
} from '@packages/wireframe/generated/desktop/html-selectors';

import {
    elementIncludesClassName,
    elementIncludesId,
} from '@/core/features/wireframe/services/wireframe-parsing-service';

const isElementHidden = (layoutId: ILayoutId | null) =>
    layoutId !== 'Desktop_S1_V1' && layoutId !== 'Desktop_S2_V1' && layoutId !== 'Desktop_S3_V1';

const showElement = (element: Element | HTMLElement) => {
    if (element instanceof HTMLElement) {
        element.setAttribute('style', 'display: block');
        return;
    }
    element.attribs.style = 'display: block';
};

const hideElement = (element: Element | HTMLElement) => {
    if (element instanceof HTMLElement) {
        element.setAttribute('style', 'display: none');
        return;
    }
    element.attribs.style = 'display: none';
};

const showElementSSR = (layoutId: ILayoutId | null, element: Element) => {
    if (isElementHidden(layoutId)) {
        return;
    }
    element.attribs.style = 'display: block';
};

const hideElementSSR = (layoutId: ILayoutId | null, element: Element) => {
    if (isElementHidden(layoutId)) {
        return;
    }
    element.attribs.style = 'display: none';
};

export const updateVisibilitySSR = (layoutId: ILayoutId | null, element: Element) => {
    if (elementIncludesId(element, idC24SearchForm) || elementIncludesClassName(element, classC24Notification)) {
        hideElementSSR(layoutId, element);
    }
    if (elementIncludesClassName(element, classC24SearchIcon)) {
        showElementSSR(layoutId, element);
    }
};

export const updateVisibilityOnClientDesktop = (layoutId: ILayoutId | null) => {
    const searchForm = document.getElementById(`${idC24SearchForm}`);
    const searchInput = document.getElementById(`${idC24SearchHeader}`);
    const searchIcon = document.querySelector(`.${classC24SearchIcon}.${classC24HeaderHover}`);
    const notificationIcon = document.querySelector(`.${classC24Notification}`);

    if (
        !(searchForm instanceof HTMLElement) ||
        !(searchInput instanceof HTMLElement) ||
        !(searchIcon instanceof HTMLElement) ||
        !(notificationIcon instanceof HTMLElement)
    ) {
        return;
    }

    if (isElementHidden(layoutId)) {
        showElement(searchForm);
        showElement(notificationIcon);
        hideElement(searchIcon);
        return;
    }

    hideElement(searchForm);
    hideElement(notificationIcon);
    showElement(searchIcon);

    searchIcon.addEventListener('click', () => {
        hideElement(searchIcon);
        searchIcon.classList.remove('open');
        showElement(searchForm);
        searchInput.focus();
    });
};
