/* This file was automatically generated */
export const classC24CookieConsentNotice = 'c24-cookie-consent-notice';
export const classC24CookieConsentButton = 'c24-cookie-consent-button';
export const classC24Header = 'c24-header';
export const classC24Customer = 'c24-customer';
export const classC24HeaderHover = 'c24-header-hover';
export const classC24CustomerHover = 'c24-customer-hover';
export const classC24HeaderHoverLayer = 'c24-header-hover-layer';
export const classC24MeinkontoRegisterLink = 'c24-meinkonto-register-link';
export const classC24CustomerCheck = 'c24-customer-check';
export const classC24Mylists = 'c24-mylists';
export const classAll = 'all';
export const classC24Notification = 'c24-notification';
export const classC24NotificationIcon = 'c24-notification-icon';
export const classC24NotificationCount = 'c24-notification-count';
export const classC24NotificationLayer = 'c24-notification-layer';
export const classC24ContactLayer = 'c24-contact-layer';
export const classC24SearchIcon = 'c24-search-icon';
export const classC24FooterLinkConditions = 'c24-footer-link-conditions';
export const classC24FooterCopyright = 'c24-footer-copyright';
export const idC24PageContainerHeader = 'c24-page-container-header';
export const idC24Header = 'c24-header';
export const idC24MeinkontoAnmelden = 'c24-meinkonto-anmelden';
export const idC24Mylists = 'c24-mylists';
export const idC24SearchForm = 'c24-search-form';
export const idC24SearchHeader = 'c24-search-header';
export const idC24Content = 'c24-content';
export const idC24PageContainerFooter = 'c24-page-container-footer';