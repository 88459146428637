/* eslint-disable */
import { NEXT_PUBLIC_CHECK24_DOMAIN, NEXT_PUBLIC_SSO_ENV, NEXT_PUBLIC_SSO_PRODUCT_ID } from '@/constants/env';
import { IUnifiedLoginResponseData } from '@/core/features/wireframe/wireframe-types';
import { getURL } from '@/core/utils/url';
import { SupportedWireframeDevices } from 'wireframe/wireframe-config';

export default function initializeLoginLayer(deviceoutput: SupportedWireframeDevices): void {
    if (!window.Check24) {
        throw 'window.Check24 is not initialized';
    }

    const wireframeScript = window.Check24;

    if (wireframeScript.uliloginlayer.initialized) {
        return;
    }

    if (!NEXT_PUBLIC_SSO_PRODUCT_ID || !NEXT_PUBLIC_CHECK24_DOMAIN || !NEXT_PUBLIC_SSO_ENV) {
        throw new Error(
            'NEXT_PUBLIC_SSO_PRODUCT_ID, NEXT_PUBLIC_CHECK24_DOMAIN or NEXT_PUBLIC_SSO_ENV are not defined during Wireframe LoginLayer initialization',
        );
    }

    wireframeScript.uliloginlayer.config.env = `${NEXT_PUBLIC_SSO_ENV}`;

    wireframeScript.uliloginlayer.closeDefaultAfter = (): void => {
        for (let i = 0; i < wireframeScript.uliloginlayer.historyCount; i++) {
            history.back();
        }
    };

    wireframeScript.uliloginlayer.closeAfter = (data: IUnifiedLoginResponseData | undefined): void => {
        if (data?.LOGINTYPE === undefined || data?.LOGINTYPE === '') {
            return;
        }

        window.location.reload();
    };

    wireframeScript.uliloginlayer.init({
        api_product: NEXT_PUBLIC_SSO_PRODUCT_ID,
        deviceoutput,
        login_email: '',
        login_phone: '',
        login_type: '',
        social_login_base_url: `https://kundenbereich.${NEXT_PUBLIC_CHECK24_DOMAIN}/`,
        social_login_callback_url: encodeURIComponent(window.location.href),
        social_login_closing_url: encodeURIComponent(getLoginClosingUrl()),
    });
}

const getLoginClosingUrl = (): string => {
    const { origin, protocol, host } = window.location;

    return getURL('/login-closing', origin || `${protocol}//${host}`).toString();
};
