/* eslint-disable fp/no-mutation */

import type { SupportedWireframeDevices } from '@packages/wireframe/wireframe-config';

import {
    classC24CustomerCheck,
    classC24MeinkontoRegisterLink,
    idC24MeinkontoAnmelden,
} from '@packages/wireframe/generated/desktop/html-selectors';
import { classC24mKbLogoutNavi, idC24mKbLoginNavi } from '@packages/wireframe/generated/mobile/html-selectors';

import { NEXT_PUBLIC_CHECK24_DOMAIN, NEXT_PUBLIC_HOST_NAME } from '@/constants/env';
import { getUrlPathWithSearch, removeTrailingQuestionmark } from '@/core/utils/url';

type LinkElement = HTMLAnchorElement | null;

export const LOGIN_LINK = 'login';
export const REGISTER_LINK = 'registrieren';
export const LOGOUT_LINK = 'logout';
export const LOGOUT_LINK_DESKTOP = 'logout.html';

export const getUserAccountUrl = (deviceoutput: SupportedWireframeDevices, check24Domain: string) => {
    return deviceoutput === 'desktop'
        ? `https://kundenbereich.${check24Domain}/user`
        : `https://m.${check24Domain}/kundenbereich`;
};

export const replaceWireframeUrlsClientSide = (routerUrl: string, isMobile: boolean) => {
    if (!NEXT_PUBLIC_CHECK24_DOMAIN) {
        throw 'NEXT_PUBLIC_CHECK24_DOMAIN is undefined in replaceWireframeUrls';
    }
    const accountUrl = getUserAccountUrl(isMobile ? 'mobile' : 'desktop', NEXT_PUBLIC_CHECK24_DOMAIN);
    if (isMobile) {
        replaceWireframeUrlsClientSideMobile(routerUrl, accountUrl);
    } else {
        replaceWireframeUrlsClientSideDesktop(routerUrl, accountUrl);
    }
};

const replaceWireframeUrlsClientSideMobile = (routerUrl: string, accountUrl: string) => {
    const loginLinkNavi: LinkElement = document.querySelector(`#${idC24mKbLoginNavi}`);
    const logoutLinkHamburger: LinkElement = document.querySelector(`.${classC24mKbLogoutNavi}`);

    replaceUrls(loginLinkNavi, accountUrl, LOGIN_LINK, routerUrl);
    replaceUrls(logoutLinkHamburger, accountUrl, LOGOUT_LINK, routerUrl);
};

const replaceWireframeUrlsClientSideDesktop = (routerUrl: string, accountUrl: string) => {
    const loginLink: LinkElement = document.querySelector(`#${idC24MeinkontoAnmelden}`);
    const registerLink: LinkElement = document.querySelector(`#${classC24MeinkontoRegisterLink}`);
    const logoutLink: LinkElement =
        document.getElementsByClassName(classC24CustomerCheck)?.item(0)?.querySelector('a') ?? null;

    replaceUrls(loginLink, accountUrl, LOGIN_LINK, routerUrl);
    replaceUrls(logoutLink, accountUrl, LOGOUT_LINK_DESKTOP, routerUrl);
    replaceUrls(registerLink, accountUrl, REGISTER_LINK, routerUrl);
};

const replaceUrls = (element: LinkElement, accountUrl: string, link: string, routerUrl: string) => {
    if (!element || element instanceof HTMLAnchorElement === false) {
        return;
    }
    const baseUrl = `https://${NEXT_PUBLIC_HOST_NAME}${routerUrl}`;
    const urlPath = getUrlPathWithSearch(baseUrl);
    const encodedURI = encodeURIComponent(`https://${NEXT_PUBLIC_HOST_NAME}${removeTrailingQuestionmark(urlPath)}`);
    element.href = `${accountUrl}/${link}?ref=${encodedURI}`;
};
