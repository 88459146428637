import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { replaceWireframeUrlsClientSide } from '@/core/features/wireframe/wireframe-manipulation/url-replacer/wireframe-url-replacer-service';

export const useWireframeUrlReplacer = (props: { isMobile: boolean }) => {
    const [url] = useClientUrl();
    React.useEffect(() => {
        replaceWireframeUrlsClientSide(url, props.isMobile);
    }, [url, props.isMobile]);
};
